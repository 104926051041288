import mergeProps from "merge-props";

import { AlertDanger } from "src/common/components/ui/form/notification/AlertDanger";
import { DECISION_NEGATIVE } from "../../../utils/verification/decisions";

const UNKNOWN_VERIFICATION_ERROR = "UnknownError";
const IDENTITY_VERIFICATION_ERROR = "IdentityVerificationError";
const CAPABILITY_VERIFICATION_ERROR = "CapabilityVerificationError";
const CAPABILITY_LIMIT_VERIFICATION_ERROR = "CapabilityLimitVerificationError";

export const VerificationError = (props) => {
  const { verification, ...componentProps } = props;
  const defaultProps = { className: "px-6 py-5 rce" };
  const mergedProps = mergeProps(defaultProps, componentProps);

  const unknowError = verification?.errors?.find((error) => {
    return error.name === UNKNOWN_VERIFICATION_ERROR;
  });

  const identityVerificationError = verification?.errors?.find((error) => {
    return error.name === IDENTITY_VERIFICATION_ERROR;
  });

  const capabilityErrror = verification?.errors?.find((error) => {
    return error.name === CAPABILITY_VERIFICATION_ERROR;
  });

  const capabilityLimitErrror = verification?.errors?.find((error) => {
    return error.name === CAPABILITY_LIMIT_VERIFICATION_ERROR;
  });

  if (unknowError) {
    return (
      <AlertDanger title="Weryfikacja danych" {...mergedProps}>
        <p className="my-0">
          Przepraszamy ale nie możemy teraz przeprowadzić procesu weryfikacji. Proszę spróbuj później.
        </p>
      </AlertDanger>
    );
  }

  if (
    identityVerificationError ||
    capabilityErrror ||
    (capabilityLimitErrror && verification.decision === DECISION_NEGATIVE)
  ) {
    return (
      <AlertDanger title="Weryfikacja danych">
        <p className="my-0">
          Niestety, nasza ocena Twojej zdolności do regulowania zobowiązań finansowych nie pozwala na dokończenie
          procesu :( Spróbuj ponownie wprowadzić swoje dane, upewniając się, że są one poprawne. Jeśli wszystkie dane
          się zgadzają, a Ty wciąż widzisz ten komunikat, to niestety nie możemy udzielić Ci wynajmu. Skontaktuj sie z
          nami, aby poznać więcej szczegółów.
        </p>
      </AlertDanger>
    );
  }

  return "";
};
