import { gql } from "@apollo/client";

export const QUERY_PRODUCTS = gql`
  query Products($filters: ProductFiltersInput) {
    products(filters: $filters) {
      data {
        id
        attributes {
          name
          price
          rentPrices {
            period
            percentage
            addition
            calculated
            regular
            discounted
          }
        }
      }
    }
  }
`;
