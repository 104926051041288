import { gql } from "@apollo/client";

export const QUERY_SUPPLIERS = gql`
  query Suppliers($filters: SupplierFiltersInput) {
    suppliers(filters: $filters) {
      data {
        id
      }
    }
  }
`;
