const cmsUrl = process.env.NEXT_PUBLIC_CMS_URL;

export const search = async (productCode, overridePrice = null) => {
  const overridePriceStr = overridePrice ? `?overridePrice=${overridePrice}` : "";
  const FetchUrl = `${cmsUrl}/api/media-expert/searchProduct/${productCode}${overridePriceStr}`;

  const response = await fetch(FetchUrl, {
    method: "GET",
  });

  return {
    status: response?.status,
    data: await response.json(),
  };
};
