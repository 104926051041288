import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { FieldFloatingText } from "../../../common/components/ui/form/field/FieldFloatingText";

import MagnifierIcon from "public/svg/icons/magnifier.svg";
import { search } from "src/ecommerce/api/product";

export function FindProduct({ onChange, onError }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [product, setProduct] = useState(undefined);

  const searchProduct = async (data) => {
    const response = await search(data.product_code);

    if (response.status === 200) {
      const product = response.data;
      setProduct(product);
    } else {
      if (response?.status === 404) {
        setError("product_code", {
          type: "not_found",
          message: `Ups, nie mamy tego produktu. Sprawdź, czy wpisany przez Ciebie kod produktu jest prawidłowy. Kod jest poprawny, a produkt wciąż jest nieaktywny? Możliwe, że aktualnie nie znajduje on się w naszej ofercie. Produkty, które możesz wynająć, oznaczone są logo Gleevery. Problem się powtarza? Napisz do nas na <a href="mailto:ook@gleevery.com" class="text-danger text-decoration-underline">ook@gleevery.com</a> lub zadzwoń <a href="tel:+48573503955" class="text-danger text-decoration-underline">+48 573 503 955</a>. Z chęcią pomożemy!`,
        });
      } else {
        onError({ error: response?.data });
      }
    }
  };

  useEffect(() => {
    if (product) {
      onChange(product);
    }
  }, [product]);

  const Button = () => {
    return (
      <button type="submit" className="btn btn-lg px-5 btn-primary" data-test-search-button>
        <MagnifierIcon width={20} height={20} fill="#fff" />
      </button>
    );
  };

  return (
    <>
      <div className="mb-8">
        <form onSubmit={handleSubmit(searchProduct)} noValidate data-test-search-form>
          <FieldFloatingText
            {...register("product_code", {
              required: true,
              pattern: {
                value: /\d{6}/,
                message: "To nie jest prawidłowy kod produktu. Kod powinien składać się z 6 cyfr",
              },
            })}
            label={"Wpisz kod produktu"}
            description="Dlaczego wpisuję kod produktu?"
            descriptionTooltip="Aby wyszukać interesujący Cię produkt z oferty Media Expert, konieczne jest wpisanie jego <strong>sześciocyfrowego</strong> kodu identyfikacyjnego, który znajdziesz <strong>przy danym produkcie</strong>. Dzięki temu możliwe będzie zrealizowanie wynajmu."
            errors={errors.product_code}
            size={"lg"}
            group={true}
            groupAfterComponent={Button}
          />
        </form>
      </div>
    </>
  );
}
