import { gql } from "@apollo/client";

export const QUERY_MERCHANTS = gql`
  query Merchants($filters: MerchantMerchantFiltersInput) {
    merchantMerchants(filters: $filters) {
      data {
        id
      }
    }
  }
`;
