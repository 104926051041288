import { styled } from "../../../../../../stitches.config";
import CircleErrorIcon from "../../../../../assets/svg/circle-error.svg";

export const AlertDanger = (props) => {
  const { title, children } = props;

  const AlertDangerStyled = styled("div", {
    border: "1px solid #dee2e6",
    borderRadius: "1.25rem",
    borderColor: "#CC9592",
    color: "#912018",
    backgroundColor: "#FAF1F2",
    marginBottom: "1rem",
  });

  const AlertDangerContentStyled = styled("div", {
    fontSize: "0.75rem",
    margin: "0",
  });

  const AlertDangerTitleStyled = styled("h2", {
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    margin: "0 0 0.5rem 0",
    fontWeight: 600,
  });

  return (
    <AlertDangerStyled className="p-5 d-flex gap-2" data-test-verification-failed>
      <div>
        <CircleErrorIcon />
      </div>
      <div className="ps-lg-2">
        <AlertDangerTitleStyled>{title}</AlertDangerTitleStyled>
        <AlertDangerContentStyled>{children}</AlertDangerContentStyled>
      </div>
    </AlertDangerStyled>
  );
};
