import { isNumber, parseInt } from "lodash";

import { apolloClient } from "src/apollo-client";
import { QUERY_MERCHANTS } from "src/ecommerce/graphql/queries/query-merchants";
import { QUERY_SUPPLIERS } from "src/ecommerce/graphql/queries/query-suppliers";
import { QUERY_PRODUCTS } from "src/ecommerce/graphql/queries/query-products";

import { create } from "src/ecommerce/api/order";

import { Ecommerce } from "../../components/Ecommerce";

import { RentFindProduct } from "../rent/RentFindProduct";

import { RentLayout } from "../layouts/RentLayout";

import { PortalFormErrorsContainer } from "../functional/PortalFormErrorsContainer";
import { Header } from "src/common/components/partials/Header";
import ContactMessage from "../partials/ContactMessage";

export function RentPage() {
  return (
    <Ecommerce>
      <Header activeStep={1} />
      <RentLayout>
        <div className="row justify-content-center">
          <div className="col-24 col-md-18 rce">
            <PortalFormErrorsContainer />
            <h1 className="fs-xl fw-semibold mt-0 mb-lg-6">Wyszukaj produkt</h1>
            <RentFindProduct />
            <ContactMessage />
          </div>
        </div>
      </RentLayout>
    </Ecommerce>
  );
}

export async function getServerSideProps(context) {
  const query = context.query;
  const sku = query?.sku;
  const rentPeriod = query?.rentPeriod ? parseInt(query?.rentPeriod) : null;
  const merchantName = query?.merchant;
  const supplierName = query?.supplier;

  const orderData = {
    status: 1, // Draft
  };

  if (merchantName && supplierName && sku && rentPeriod) {
    const merchant = await queryMerchantByName(merchantName);
    const merchantId = merchant?.id ? parseInt(merchant?.id) : null;

    if (!isNumber(merchantId)) {
      return {
        notFound: true,
      };
    }

    const supplier = await querySupplierByName(supplierName);
    const supplierId = supplier?.id ? parseInt(supplier?.id) : null;

    if (!isNumber(supplierId)) {
      return {
        notFound: true,
      };
    }

    if (merchantName.toLowerCase() === "mediaexpert") {
      const product = await searchProductInME(sku);

      if (!product) {
        return {
          notFound: true,
        };
      }

      const addTags = query?.addTags || query?.addtags;
      const tagsNames = addTags?.split(",");

      orderData.merchant = merchantId;
      orderData.productPrice = product?.price;
      orderData.productName = product?.name;
      orderData.productCategoryName = product?.category;
      orderData.tags = tagsNames;
    } else {
      const product = await queryProductBySupplierAndSKU(supplierId, sku);
      const productId = product?.id ? parseInt(product?.id) : null;

      if (!isNumber(productId)) {
        return {
          notFound: true,
        };
      }

      orderData.merchant = merchantId;
      orderData.product = productId;
      orderData.rentPeriod = rentPeriod;
    }

    const order = await create({
      data: orderData,
    });

    if (order && order?.data?.attributes?.code) {
      return {
        redirect: {
          destination: `/rent/${order?.data?.attributes?.code}/period`,
        },
      };
    }
  }

  return { props: {} };
}

const queryMerchantByName = async (name) => {
  const filters = {
    name: {
      eqi: name,
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_MERCHANTS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.merchantMerchants?.data?.[0];
};

const querySupplierByName = async (name) => {
  const filters = {
    name: {
      eqi: name,
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_SUPPLIERS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.suppliers?.data?.[0];
};

const queryProductBySupplierAndSKU = async (supplier, sku) => {
  const filters = {
    offers: {
      and: {
        supplier: {
          id: {
            eq: 3,
          },
        },
        sku: {
          eq: sku,
        },
      },
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_PRODUCTS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.products?.data?.[0];
};

const searchProductInME = async (sku) => {
  const baseUrl = process.env.NEXT_PUBLIC_CMS_URL;
  const options = { method: "GET", headers: {} };

  try {
    const response = await fetch(`${baseUrl}/api/media-expert/searchProduct/${sku}`, options);
    if (response.ok) {
      const productData = await response.json();

      return {
        name: productData?.name,
        mediaExpertId: productData?.code,
        category: productData?.category?.name,
        rentPrices: [],
        tags: [],
        price: productData.price,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log("Fetch - searchProductInME Fatal error:", error);
    return null;
  }
};
